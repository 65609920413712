<template>
  <div class="Newsinvitepar">
    <div class="top-titles">
      <div class="top-text">修改招聘信息</div>
    </div>

    <!-- 上传图片 -->
    <div class="Insurance-box">
       <div class="image-btn">
         <p>发布图片：</p>
         </div>
      <div class="top-box-image">
        <el-upload
          list-type="picture-card"
           accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
          :on-remove="handleRemove"
          :on-success="handleAvatarSuccess"
          :limit='3'
          :action="upLoadUrl"
          :data="headerMsg"
          :file-list='ruleForm'
        >
          <i class="el-icon-plus"></i>
        </el-upload>
 
      </div>
    </div>
    <!-- 表单 -->
    <div class="forms">
      <el-form ref="form" label-width="120px" enctype="multipart/form-data">

        <div class="Newsinvite-form">
          <el-form-item label="标题：">
            <el-input v-model="listpar.headline"></el-input>
          </el-form-item>
        </div>

        <div class="Newsinvite-form">
          <el-form-item label="工种：">
            <el-select v-model="listpar.kind" placeholder="请选择工种" >
              <el-option
                v-for="(r, i) in list"
                :key="i"
                :label="r.a"
                :value="r.a"
              ></el-option
            ></el-select>
          </el-form-item>
        </div>
      <div class="Newsinvite-form">
          <el-form-item label="工作性质：">
            <el-select v-model="listpar.work" placeholder="请选择工作性质：">
              <el-option
                v-for="(r, i) in seeklist.two"
                :key="i"
                :label="r.label"
                :value="r.label"
              ></el-option
            ></el-select>
          </el-form-item>
        </div>

        <div class="Newsinvite-form">
          <el-form-item label="工价：">
            <el-input-number type="number" :min="0" v-model="listpar.salary"></el-input-number>&nbsp;元/时
          </el-form-item>
        </div>
        <div class="Newsinvite-form">
          <el-form-item label="招聘人数：">
            <el-input-number type="number" :min="0" v-model="listpar.recruitingNumbers" @change='calculate()' @blur="calculate()"></el-input-number>&nbsp;人
          </el-form-item>
        </div>

        <div class="Newsinvite-form duoge">
          <el-form-item label="起始工作日期" >
            <el-date-picker
              v-model="listpar.startTime"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              @blur="calculate()"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="结束工作日期">
            <el-date-picker
              v-model="listpar.finishTime"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </div>

        <div class="Newsinvite-form duoge">
          <el-form-item label="起始工作时间">
            <el-time-select
              v-model="listpar.startHour"
              :picker-options="{ start: '05:00', step: '00:10', end: '23:30' }"
              placeholder="选择时间"
              @blur="calculate()">
            </el-time-select>
          </el-form-item>
          <el-form-item label="结束工作时间">
            <el-time-select
              v-model="listpar.finisHour"
              :picker-options="{ start: '05:00', step: '00:10', end: '23:30' }"
              placeholder="选择时间" @blur="calculate()"
            >
            </el-time-select>
          </el-form-item>
        </div>

        <div class="Newsinvite-form">
          <el-form-item label="支付招聘费用">
            <el-input-number type="number" :min="0" v-model="listpar.price_toplat" @change='calculate()' @blur="calculate()"></el-input-number>&nbsp;元/时
          </el-form-item>
        </div>

        <div class="Newsinvite-form two" >
          <el-form-item label="支付招聘总费用">
            <el-input-number type="number" :min="0" v-model="listpar.promise_payplat_total" :disabled='true'></el-input-number>&nbsp;元       
          </el-form-item>
            <!-- <el-button
          type="warning"
          class="biy-icon"
          icon="el-icon-check"
          >充值</el-button> -->
        </div>



        <div class="Newsinvite-form duoge">
          <el-form-item :label-width="i == 0?'80px':'10px'" :label="i == 0 ?'招聘要求':''" v-for="(item ,i) in listpar.askFor" :key="i">
            <el-input v-model="item.yaoqiu"></el-input>
          </el-form-item>
        </div>

        <div class="Newsinvite-form">
          <el-form-item label="工厂名称：">
            <el-input v-model="listpar.companyName"></el-input>
          </el-form-item>
        </div>

        <div class="Newsinvite-form">
          <el-form-item label="联系人：">
            <el-input v-model="listpar.linkman"></el-input>
          </el-form-item>
        </div>

        <div class="Newsinvite-form">
          <el-form-item label="联系电话：">
            <el-input  v-model="listpar.phone"></el-input>
          </el-form-item>
        </div>

        <div class="Newsinvite-form">
          <el-form-item label="工厂地址：">
            <el-input v-model="listpar.companyAddress"></el-input>
          </el-form-item>
        </div>

        <div class="Newsinvite-form">
          <el-form-item label="月综合工资：">
            <el-input v-model="listpar.month_multiple_price"></el-input>
          </el-form-item>
        </div>


    <div class="Newsinvite-form">   
     <el-form-item label="备注：">
          <el-input type="textarea" v-model="listpar.remark" style="width:600px" rows="3"></el-input>
        </el-form-item>
        </div>

      <div style=" width:100%;padding-bottom:50px"> 
        <span>职业描述：</span>
          <Fwbs v-model="listpar.job" :isClear="isClear" @change="change"></Fwbs>
      </div>


        <div class="particulars-map" v-if="flag">
          工作地址：<Maps :weizhi="listpar" @jingwei="jingwei" />
        </div>

  <div class="dizhi-box">
        <div class="dizhi-li">
         <el-form-item label="查找位置:">
                  <el-input v-model="listpar.work_addr1" placeholder="请输入具体工作地址"></el-input>
          </el-form-item>
            <el-button type="primary"
            class="biy-icon" 
            icon="el-icon-check" @click="tijiao()">查询</el-button>
        </div>
        <div class="dizhi-li" style="color:red; font-size:12px">
            具体地址查询须精准到市级下辖行政区域
        </div>
</div>

      </el-form>
      <div class="form-btn">
       <el-button
          type="primary"
          class="biy-icon"
          icon="el-icon-check"
          @click="SinviteBtn()"
          >发布到待招聘</el-button>
        <el-button
          type="primary"
          class="biy-icon"
          icon="el-icon-check"
          @click="fabuBtn()"
          >存入到草稿箱</el-button>
      </div>
    </div>

  </div>
</template>
<script>
import Maps from "@/components/maps/Maps.vue";
import fakelist from "@/api/fakelist.js"
import apiurl from "@/api/apiurl/apiurl.js";
import config from "@/request/config.js";

import Fwbs from '@/components/fwb/Fwb.vue';
export default {
  name: "newsinvite",
  components: { Maps,Fwbs },
  data() {
    return {
      // 上传图片的地址
      upLoadUrl:config.uploadingimg,
      
      // 图片路径地址
      upLoadUrlto:config.ImgUrl,

      headerMsg:{
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
      uploadfile:'file',
      },

             // 富文本的值
      isClear: false,
      // 下拉框
      seeks: {},
      seeklist: fakelist.newsinvite,

      // 上传图片
      imageUrl: "",
      // 里面图片的
      imgss:'',

      ditu:{},
      list:[],
      listpar: {
        askFor:[
          {},
          {},
          {},
          {},
          {},
        ],
      },
      flag:false,

      dialogVisible:false,
      ruleForm:[],


    };
  },
  created() {
      this.getSong()
      this.gongzhong()
  },
  mounted() {
    
  },

  methods: {

    // 输入框失去焦点时计算中费用
    calculate(){
     
     this.calculates()
    },
    async calculates(){
   let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
      bizCatalog :"RecruitEntity",
      handleMode:"getNewPayplatTotal",
      bizData:{
      id:this.listpar.id,
      price_toplat:this.listpar.price_toplat,
      begin_datetime:this.listpar.startTime,
      finish_datetime:this.listpar.finishTime,
      begin_hour:this.listpar.startHour,
      finish_hour:this.listpar.finisHour,
      need_total:this.listpar.recruitingNumbers,
      }
     })
     this.listpar.promise_payplat_total = res.Result
    },

    //经纬度
      jingwei(val){
 
          this.listpar.work_addr = val.addressComponents

          this.listpar.longitude = val.location.lng
          this.listpar.latitude = val.location.lat
      },

          // 工种
      async gongzhong(){
          let res = await apiurl.baseConfigDAL({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
            handleMode:'get',
            cfgCatalog:'RecruitWorkType', })
            if(this.$common.verifyAjaxResult(res,false)==false) return;
            this.list = res.Result.list
      },
    //查询接口
      async getSong(){
				let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        handleMode:'fetch',
        bizCatalog:'RecruitEntity',
        bizId:this.$route.params.id,
        })
        console.log(res.Result);
        if(this.$common.verifyAjaxResult(res,false)==false) return;
          this.listpar = res.Result;

          for (let i = 0; i < res.Result.profile_photos.length; i++) {
           const item = res.Result.profile_photos[i];
            this.ruleForm.push({'url':item,'uid': i+2});           
         }

          this.listpar.zoom = 14;
          this.flag=true
      },

    //弹窗
      SinviteBtn(){
          this.$confirm('是否确认发布到待招聘', '消息提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消操作'
        }).then(() => {
          this.SinviteBtns()
   
          }).catch(action => {
            console.log("取消");
          })
      },
      // 发布到待招聘
     async SinviteBtns(){
           let askFor=[];
         for (let i = 0; i < this.listpar.askFor.length; i++) {
           const item = this.listpar.askFor[i];
            askFor.push({"yaoqiu":item["yaoqiu"]});           
         }

             let ruleForms=[];
         for (let i = 0; i < this.ruleForm.length; i++) {
           const item = this.ruleForm[i];
            ruleForms.push(item["url"]);           
         }


      let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
      bizCatalog :"RecruitEntity",
      handleMode:"upsertLaunchAudit",
      bizData:{
      id:this.listpar.id,

      profile_photos:ruleForms, 

       work_addr1:this.listpar.work_addr1,
      salary:this.listpar.salary,
      price_toplat:this.listpar.price_toplat,
      work_addr:this.listpar.work_addr,
      status:this.listpar.status,
      headline:this.listpar.headline,
      kind:this.listpar.kind,
      recruitingNumbers:this.listpar.recruitingNumbers,
      estimated:this.listpar.estimated,
      work:this.listpar.work,
      startTime:this.listpar.startTime,
      finishTime:this.listpar.finishTime,
      startHour:this.listpar.startHour,
      finisHour:this.listpar.finisHour,
      askFor:askFor,
      companyName:this.listpar.companyName,
      linkman:this.listpar.linkman,
      phone:this.listpar.phone,
      companyAddress:this.listpar.companyAddress,
      month_multiple_price:this.listpar.month_multiple_price,
      job:this.listpar.job,
      remark:this.listpar.remark,
      // 经纬度
      longitude: this.listpar.longitude,
      latitude: this.listpar.latitude,
      }
     })
      
      if(this.$common.verifyAjaxResult(res,false)==false) return;
        this.$alert('发布成功', '消息提示', {
          confirmButtonText: '确定',
          callback: action => {
            this.$router.push({ path: `/home/lookinvite`});
          }
        });
      },

    //弹窗
      fabuBtn(){
          this.$confirm('是否确认存入到草稿箱', '消息提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消操作'
        }).then(() => {
          this.fabuBtns()
             
          }).catch(action => {
            console.log("取消");
          })
      },
      // 存入到草稿箱
   async fabuBtns(){

         let askFor=[];
         for (let i = 0; i < this.listpar.askFor.length; i++) {
           const item = this.listpar.askFor[i];
            askFor.push({"yaoqiu":item["yaoqiu"]});           
         }

             let ruleForms=[];
         for (let i = 0; i < this.ruleForm.length; i++) {
           const item = this.ruleForm[i];
            ruleForms.push(item["url"]);           
         }

      let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
      bizCatalog :"RecruitEntity",
      handleMode:"upsertReset",
      bizData:{
      id:this.listpar.id,

      profile_photos:ruleForms, 

      work_addr1:this.listpar.work_addr1,
      salary:this.listpar.salary,
      price_toplat:this.listpar.price_toplat,
      work_addr:this.listpar.work_addr,
      status:this.listpar.status,
      headline:this.listpar.headline,
      kind:this.listpar.kind,
      recruitingNumbers:this.listpar.recruitingNumbers,
      estimated:this.listpar.estimated,
      work:this.listpar.work,
      startTime:this.listpar.startTime,
      finishTime:this.listpar.finishTime,
      startHour:this.listpar.startHour,
      finisHour:this.listpar.finisHour,
      askFor:askFor,
      companyName:this.listpar.companyName,
      linkman:this.listpar.linkman,
      phone:this.listpar.phone,
      companyAddress:this.listpar.companyAddress,
      month_multiple_price:this.listpar.month_multiple_price,
      job:this.listpar.job,
      remark:this.listpar.remark,
      // 经纬度
      longitude: this.listpar.longitude,
      latitude: this.listpar.latitude,
      }
     })

      if(this.$common.verifyAjaxResult(res,false)==false) return;
        this.$alert('存入成功', '消息提示', {
          confirmButtonText: '确定',
          callback: action => {
            this.$router.push({ path: `/home/lookinvite`});
          }
        });
      },


// 地图搜索
    tijiao(){
      this.flag = false
      //  input失焦获取填写地址
      let that = this
      $.ajax({
        type: "get",
        dataType: 'jsonp',
        data: {
          key: "EO2BZ-I3CAV-HO7PP-UT2A3-XVWW7-ECBF4", // 填申请到的腾讯key
          address: that.listpar.work_addr1, //具体的地址
          output: 'jsonp' //返回格式：支持JSON/JSONP，默认JSON
        },
        jsonp: "callback",
        jsonpCallback: "QQmap",
        url: "https://apis.map.qq.com/ws/geocoder/v1/?",
        success: function (json) {
          if (json.status == 0) {                               
            that.listpar.longitude = json.result.location.lng.toString() 
            that.listpar.latitude = json.result.location.lat.toString()
            that.listpar.work_addr = json.result["address_components"]
                if (json.result.reliability.toString() == 1) {
                    that.listpar.zoom = 7
                 }else{
                    that.listpar.zoom = 14
                 }
            that.flag = true
 
            that.$message({
              message: '成功获取位置的经纬度',
              type: 'success'
            })
          } else {
            that.flag = true
            that.$message.error('获取该位置经纬度失败')
          }
        },
        error: function (err) {
           that.flag = true
          that.$message.error('异常错误，请刷新浏览器后重试')
        }
      })
    },

     // 富文本
      change() { 
      }, 

// 图片移除成功时
      handleRemove(file, fileList) {
        this.ruleForm = fileList 
        console.log(this.ruleForm);
      },

    // 图片上传成功时
    handleAvatarSuccess(res, file) {
      this.ruleForm.push({'url':this.upLoadUrlto+file.response.Result,'uid':file.uid});
      console.log(this.ruleForm);
    },

    beforeAvatarUpload() {
      console.log("");
    },
  },
};
</script>


<style  scoped>

/* 搜索栏样式 */
.top-titles {
  width: 94%;
  margin: 0 auto;
  height: 70px;
}
.top-text {
  height: 30px;
  font-size: 18px;
}

.forms {
  width: 800px;
  margin: 0 auto;
}
.Newsinvite-form {
  width: 400px;
  /* margin: 0 auto; */
}
.Newsinvite-form.two {
  width: 700px;
  display: flex;
  /* margin: 0 auto; */
}
.Newsinvite-form.two .biy-icon{
  height: 40px;
  margin-left: 40px;
}
.Newsinvite-form.duoge {
  width: 700px;
  display: flex;
  justify-content: space-between;
  /* margin: 0 auto; */
}
.form-btn {
  width: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 100px;
}
.form-btn .biy-icon {
  width: 250px;
  border-radius: 10px;
  background-color: #2ab6708a;
}
.form-btn .biy-icon:nth-of-type(1) {
  background-color: #01847f;
}
/* 地址 */
.dizhi-box{
  padding-top: 20px;
  width: 100%;
}
.dizhi-li{
  width: 800px;
  margin: 0 auto;
  height: 40px;
  display: flex;
  justify-content: center;
}


.Insurance-box{
  padding-top: 30px;
  width: 100%;
  margin: 0 auto;
  display: flex !important;
  justify-content: center !important;
  padding-bottom: 50px;
  position: relative;
}
.form-btn {
  padding-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.form-btn .biy-icon {
  width: 300px;
}

/* 上传 */
.top-box-image {
  text-align: center;
  /* height: 100px; */
  font-size: 15px;
  overflow: hidden;
  display: flex;
  line-height: 100px;
  padding-left: 50px;
}
.image-btn{
  text-align: right;
  line-height: 50px
}
.image-btn p:nth-of-type(2){
cursor: pointer;
border: 1px dashed #999
}




.particulars-map{
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>